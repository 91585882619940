
export const getPressReleases = async () => {
  try {
    const response = await fetch("/press-releases"); // Fetch from node proxy
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching press releases:", error.message);
  }
}
